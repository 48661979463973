import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"


import styled from "@emotion/styled"
import { Global, css } from "@emotion/react"
import {color, spacing} from "../constants"

import NewsletterForm from "./newsletterForm"

const flexColumn = css`
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`

const Footer = (props) => {
  return (
    <div css={css`
        border-top: 1px ${color.dark} solid;
        margin-top: ${props.marginTop ? '100px' : '0' };
        padding: ${spacing.medium} 0 ${spacing.xs} 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        & > div {
          padding: 0 ${spacing.xs};
        }
        @media screen and (max-width: 600px) {
          flex-direction: column;
          & > div {
            margin: 1em auto auto 0;
          }
        }   
    `}>
      <div css={flexColumn}>
        <NewsletterForm />
      </div>
      <div css={flexColumn} style={{paddingRight: '10px'}}>
        <Link to="/" css={css`text-decoration: none; color: ${color.dark};`}><h5 h5 css={css`margin: 0; font-size: 1em;`}>GML Kreuzlingen, est. 1917</h5></Link>
        <p css={css`font-size: 0.9em;`}>
          Bankverbindung:<br/>
          Raiffeisenbank Tägerwilen<br/>
          IBAN: CH06 8141 2000 0083 9958 1<br/>
          BIC: RAIFCH22<br/>
        </p>
      </div>
    </div>
  )
}

export default Footer
